import React from 'react'
import { Box, Card, Flex, Heading, css } from 'theme-ui'
import { buildResponsiveVariant as rv } from '../../../../packages/theme-ui/src/utils'
import Divider from '@components/Divider'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const styles = {
  card: {
    overflow: `hidden`,
    height: `full`,
    maxWidth: 500
  },
  content: {
    alignItems: `stretch`,
    height: `full`
  },
  body: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    flex: 1,
    height: '2.5em'
  },
  heading: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '1.25em',
    color: `grayDark`
  }
}

export default function ProductCard(props) {
  const name = props.data.name
  const slug = '/private-label/' + props.data.slug + '/'
  const image = props.data.images[0].localFile.childImageSharp.fluid
  return (
    <Box className='blog_card' key={name + 'box'}>
      <Card
        variant='interactive'
        sx={{
          ...styles.card,
          variant: rv('vertical', 'card')
        }}
        key={name + 'card'}
      >
        <Link to={slug} style={{ textDecoration: 'none' }} aria-label={name} key={name + 'link'}>
          <Flex
            as='article'
            sx={{
              ...styles.content,
              variant: rv('vertical', 'content')
            }}
            key={name + 'flex'}
          ></Flex>
          <Divider space={2} key={name + 'divider'}/>
          <Box sx={{ variant: rv('vertical', 'media') }} key={name + 'box2'}>
            <Img
              fluid={image}
              alt={'bottle of private labeled ' + name + ' with white background'}
              title={name}
              loading='lazy'
              fadeIn={true}
              css={css({
                height: `full`,
                variant: rv('vertical', 'image')
              })}
              key={name + 'img'}
              imgStyle={{
                width: '100%',
                maxWidth: 500,
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center',
                paddingLeft: '5%',
                paddingRight: '5%'
              }}
              style={{
                width: '100%',
                maxWidth: 500,
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center',
                paddingLeft: '5%',
                paddingRight: '5%'
              }}
              placeholderStyle={{
                width: '100%',
                maxWidth: 500,
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center',
                paddingLeft: '5%',
                paddingRight: '5%'
              }}
            />
            <Divider space={2} key={name + 'divider2'} />
          </Box>
          <Box
            sx={{
              ...styles.body,
              variant: rv('vertical', 'body')
            }}
            key={name + 'box3'}
          >
            <Heading
              variant='h2'
              sx={{ ...styles.heading, variant: rv('vertical', 'title') }}
              key={name + 'heading'}
            >
              {name}
            </Heading>
          </Box>
        </Link>
      </Card>
    </Box>
  )
}
