import React from 'react'
import { Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Hero from '../components/Homepage/Hero/Hero'
import Info from '../components/Homepage/Info/Info'
import Services from '../components/Homepage/Services/Services'
import BusModels from '../components/Homepage/BusModels/BusModels'
import Concept from '../components/Homepage/Concept/Concept'
import Featured from '../components/Homepage/Featured/Featured'
import Contact from '../components/Homepage/Contact/Contact'

const Home = () => {
  
  return (
    <>
      <Seo
        title='Private Label Skincare Program'
        description="Pacific Beauty is a distributor of American-made private label skincare.  We work with the world's leading skincare manufacturer to deliver scientifically proven products."
      />
      <Divider />
      <Stack>
        <Main>
          <Hero />
        </Main>
      </Stack>
      <Divider />
      <Divider />
      <Stack>
        <Main>
          <Services />
        </Main>
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <Info />
        </Main>
      </Stack>
      <Divider />
      <BusModels />
      <Stack>
        <Main>
          <Concept />
        </Main>
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <Featured />
        </Main>
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <Contact />
        </Main>
      </Stack>
    </>
  )
}

export default Home
