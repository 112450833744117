import React from 'react'
import { Heading } from 'theme-ui'
import Section from '@components/Section'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    //textAlign: 'center',
    width: `100%`,
  },
  subheading: {
    //textAlign: 'center',
    width: `100%`,
  },
  running: {
    color: `grayDark`,
    fontWeight: `body`,
    width: `100%`
  }
}

export default () => (
  <>
    <Section>
      <Heading variant='h2' sx={styles.heading}>
        From Concept to Shelf
      </Heading>
      <Heading variant='h3' sx={styles.subheading}>
        we help you every step of the way:
      </Heading>
      <Heading variant='h3' sx={styles.running}>
        <ul>
          <li>Brand Identity</li>
          <li>Product Selection</li>
          <li>Package, label, &amp; logo design</li>
          <li>Production</li>
          <li>Shipping</li>
          <li>Regulatory Compliance &amp; <br />documentation</li>
          <li>Marketing</li>
        </ul>
      </Heading>
    </Section>
  </>
)

