import React from 'react'
import { Box, Grid, Heading, Text } from 'theme-ui'
import IconButton from '../../IconButton'
import Section from '@components/Section'

const styles = {
  horizontal: {
    display: `flex`,
    justifyContent: [`flex-start`, `space-evenly`],
    flexWrap: `nowrap`,
    overflowX: `auto`,
    width: `auto`,
    scrollBehavior: `smooth`,
    m: -2,
    a: {
      flex: 1,
      minWidth: [`1/3`, `auto`],
      m: [3, 2]
    }
  },
  center: {
    ml: 'auto',
    mr: 'auto',
    textAlign: 'center'
  },
  large: {
    fontSize: '2.5em'
  }
}

export default () => (
  <Box>
    <Heading variant='h2' sx={styles.large}>
      <Text sx={styles.center}>Services We Offer</Text>
    </Heading>
    <Section title='Topics' omitTitle>
      <Grid gap={10} columns={[1, 1, 1, 3]} sx={styles.center}>
        <Box sx={styles.horizontal}>
          <IconButton
            name='Private Label'
            to='/services/private-label/'
            iconPath={require('../../../../content/assets/icons/private-label.svg')}
          />
        </Box>
        <Box sx={styles.horizontal}>
          <IconButton
            name='Custom Formulation'
            to='/services/custom-formulation/'
            iconPath={require('../../../../content/assets/icons/custom-formulation.svg')}
          />
        </Box>
        <Box sx={styles.horizontal}>
          <IconButton
            name='Contract Manufacturing'
            to='/services/contract-manufacturing/'
            iconPath={require('../../../../content/assets/icons/contract-manufacturing.svg')}
          />
        </Box>
      </Grid>
    </Section>
  </Box>
)
