import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box } from 'theme-ui'
import Img from 'gatsby-image'

const styles = {
  myBox: {
    display: [`none`, `none`, 'none', `block`],
    width: '100%',
    height: '140%',
    maxWidth: 486,
    maxHeight: 514,
    ml: 'auto',
    mr: 'auto',
    textAlign: 'center',
    borderRadius: `default`,
    // filter: `grayscale(1)`,
    overflowX: `visible`,
    position: `absolute`,
    bottom: 0,
    right: 0,
    zIndex: 2
  }
}

export default () => {
  const data = useStaticQuery(heroQuery)
  const fluid = data.file.childImageSharp.fluid

  return (
    <Box sx={styles.myBox}>
      <Img fluid={fluid} title='' alt='' loading='lazy' />
    </Box>
  )
}

const heroQuery = graphql`
  query HeroQuery2 {
    file(absolutePath: { regex: "/hero.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 486, maxHeight: 514, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
