import React from 'react'
import { Box, Grid } from 'theme-ui'
import Section from '@components/Section'
import Product from '../../ProductCard/ProductCard'
import { graphql, useStaticQuery } from 'gatsby'

const styles = {
  center: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  }
}

export default () => {
  const data = useStaticQuery(featuredQuery)
  const products = data.allWcProducts.nodes

  return (
    <>
      <Section
        title='Featured Products'
        titleLink={'/private-label/'}
        sx={styles.featured}
      >
        <Grid gap={3} columns={[1, 1, 3, 3]} sx={styles.center}>
          {products.map(product => (
            <Box key={product.id}>
              <Product data={product} />
            </Box>
          ))}
        </Grid>
      </Section>
    </>
  )
}

const featuredQuery = graphql`
  query FeaturedQuery {
    allWcProducts(
      filter: { featured: { eq: true }, status: { eq: "publish" } }
      limit: 9
      sort: { order: DESC, fields: date_modified }
    ) {
      nodes {
        id
        name
        slug
        images {
          localFile {
            childImageSharp {
              fluid(cropFocus: CENTER, maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
