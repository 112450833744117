import React from 'react'
import { css } from 'theme-ui'
import { Card, Text, Heading, Box, Button } from 'theme-ui'
import { FaRegPaperPlane, FaWind } from 'react-icons/fa'
import { Link } from 'gatsby'

const styles = {
  card: {
    position: `relative`
  },
  wrapper: {
    maxWidth: 500,
    textAlign: `center`,
    mx: `auto`,
    py: 3
  },
  icons: {
    display: [`none`, `none`, `block`],
    position: `absolute`,
    top: `-3rem`,
    left: `5rem`,
    svg: {
      display: `block`
    }
  },
  plane: {
    fontSize: `7rem`,
    color: `primary`
  },
  wind: {
    fontSize: `7rem`,
    color: `grayLight`,
    transform: `rotate(120deg)`,
    mt: `0.5rem`,
    ml: `-3rem`
  }
}

export default () => (
  <Card variant='paper' sx={styles.card}>
    <Box sx={styles.wrapper}>
      <Box sx={styles.icons}>
        <FaRegPaperPlane css={css(styles.plane)} />
        <FaWind css={css(styles.wind)} />
      </Box>
      <Heading variant='h2'>Contact Us Today!</Heading>
      <Text variant='p'>
        Get in touch with us to learn how you can begin private labeling your own branded skincare.
      </Text>
      <Box variant='buttons.group'>
        <Button as={Link} to='/contact/'>
          Contact Us
        </Button>
      </Box>
    </Box>
  </Card>
)
