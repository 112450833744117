import React from 'react'
import SVG from 'react-inlinesvg'
import { Main } from '@layout'
import { Box, Grid, Heading, Text } from 'theme-ui'
import HeroWide from '@widgets/HeroWide'
import Divider from '@components/Divider'

const styles = {
  heading: {
    span: {
      color: `grayDark`
    },
    fontSize: '2.5em'
  },
  subheading: {
    color: 'primary'
  },
  center: {
    ml: 'auto',
    mr: 'auto',
    textAlign: 'center'
  }
}

const ico = {
  size: '4em'
}

export default () => (
  <Main>
    <HeroWide.Wrapper sx={{ py: 3 }}>
      <HeroWide.LeftColumn>
        <Heading variant='h2' sx={styles.heading}>
          <Text sx={styles.center}>
            Some of the <span>Business Models</span> we cater to:
          </Text>
        </Heading>
      </HeroWide.LeftColumn>
    </HeroWide.Wrapper>
    <Divider />
    <Grid gap={2} columns={[1, 2, 2, 4]}>
      <Box sx={styles.center}>
        <SVG
          src={require('../../../../content/assets/icons/amazon-sellers.svg')}
          width={ico.size}
          height={ico.size}
          title='Amazon Sellers Icon'
        />
        <Heading variant='h2' sx={styles.subheading}>
          Amazon Sellers
        </Heading>
      </Box>
      <Box sx={styles.center}>
        <SVG
          src={require('../../../../content/assets/icons/social-media-influencers.svg')}
          width={ico.size}
          height={ico.size}
          title='Social Media Influencers Icon'
        />
        <Heading variant='h2' sx={styles.subheading}>
          <Text sx={{ textAlign: 'center' }}>Social Media Influencers</Text>
        </Heading>
      </Box>
      <Box sx={styles.center}>
        <SVG
          src={require('../../../../content/assets/icons/established-brands.svg')}
          width={ico.size}
          height={ico.size}
          title='Established Brands Icon'
        />
        <Heading variant='h2' sx={styles.subheading}>
          <Text sx={{ textAlign: 'center' }}>Established Brands</Text>
        </Heading>
      </Box>
      <Box sx={styles.center}>
        <SVG
          src={require('../../../../content/assets/icons/spas-and-salons.svg')}
          width={ico.size}
          height={ico.size}
          title='Spas and Salons Icon'
        />
        <Heading variant='h2' sx={styles.subheading}>
          <Text sx={{ textAlign: 'center' }}>Spas &amp; Salons</Text>
        </Heading>
      </Box>
    </Grid>
    <Grid gap={2} columns={[1, 2, 2, 4]}>
      <Box sx={styles.center}>
        <SVG
          src={require('../../../../content/assets/icons/dermatologists.svg')}
          width={ico.size}
          height={ico.size}
          title='Dermatologists Icon'
        />
        <Heading variant='h2' sx={styles.subheading}>
          <Text sx={{ textAlign: 'center' }}>Dermatologists</Text>
        </Heading>
      </Box>
      <Box sx={styles.center}>
        <SVG
          src={require('../../../../content/assets/icons/esthetic-medical-professionals.svg')}
          width={ico.size}
          height={ico.size}
          title='Esthetic Medical Professionals Icon'
        />
        <Heading variant='h2' sx={styles.subheading}>
          <Text sx={{ textAlign: 'center' }}>
            Esthetic Medical Professionals
          </Text>
        </Heading>
      </Box>
      <Box sx={styles.center}>
        <SVG
          src={require('../../../../content/assets/icons/retail-stores.svg')}
          width={ico.size}
          height={ico.size}
          title='Retail Stores Icon'
        />
        <Heading variant='h2' sx={styles.subheading}>
          <Text sx={{ textAlign: 'center' }}>Retail Stores</Text>
        </Heading>
      </Box>
      <Box sx={styles.center}>
        <SVG
          src={require('../../../../content/assets/icons/many-more.svg')}
          width={ico.size}
          height={ico.size}
          title='Many More Icon'
        />
        <Heading variant='h2' sx={styles.subheading}>
          <Text sx={{ textAlign: 'center' }}>Many More</Text>
        </Heading>
      </Box>
    </Grid>
  </Main>
)
