import React from 'react'
import HeroBoxed from '@widgets/HeroBoxed'
import Content from './Concept.Content'
import Photo from './Concept.Photo'

const styles = {
  photo: {
    display: 'none'
  }
}

export default () => (
  <HeroBoxed.Wrapper>
    <HeroBoxed.LeftColumn>
      <Content />
    </HeroBoxed.LeftColumn>
    <HeroBoxed.RightColumn sx={styles.photo}>
      <Photo />
    </HeroBoxed.RightColumn>
  </HeroBoxed.Wrapper>
)
