import React from 'react'
import { Heading, Box } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  large: {
    fontSize: '2.5em'
  },
  running: {
    color: `grayDark`,
    fontWeight: `body`,
    span: {
      fontWeight: 'bold'
    }
  }
}

export default () => (
  <Box sx={styles.wrapper}>
    <Heading variant='h2' sx={styles.large}>
      Creating Your Own Skincare Line Just Got Easier.
    </Heading>
    <Heading variant='h3' sx={styles.running}>
      <span>Pacific Beauty</span> is a distributor of American-made private
      label skincare products with a specialization in international markets. We
      excel at helping our partners with their country’s regulatory compliance
      and we work with the world’s leading skincare manufacturer to deliver
      high-quality and scientifically-proven personal care products. From small
      batches of 48 units to large batches of 100,000+, Pacific Beauty is your
      partner in private label skincare. All of our products are manufactured in
      a world-class ISO 22716 Certified, cGMP Accredited and FDA-Registered
      facility; all of our products are made in the USA; all of our products are
      cruelty-free and are never tested on animals. Make your dream of starting
      a skincare line a reality. Get started today.
    </Heading>
  </Box>
)
