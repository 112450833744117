import React from 'react'
import { Hero } from '@layout'
import Divider from '@components/Divider'
import Content from './BusModels.Content'

const styles = {
  bgwhite: {
    bg: 'contentBg'
  }
}

export default () => (
  <Hero sx={styles.bgwhite}>
    <Divider space={60} />
    <Content />
    <Divider space={60} />
  </Hero>
)
