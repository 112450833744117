import React from 'react'
import HeroBoxed from '@widgets/HeroBoxed'
import Content from './Info.Content'

export default () => (
  <HeroBoxed.Wrapper>
    <HeroBoxed.LeftColumn>
      <Content />
    </HeroBoxed.LeftColumn>
  </HeroBoxed.Wrapper>
)
