import React from 'react'
import MemphisPattern from '@components/MemphisPattern'
import HeroBoxed from '@widgets/HeroBoxed'
import Content from './Hero.Content'
import Photo from './Hero.Photo2'

const styles = {
  pattern: {
    display: [`none`, `none`, 'none', `block`],
    width: `1/4`,
    opacity: 0.1,
    left: `auto`,
    right: 0,
    bottom: 0
  }
}

export default () => (
  <HeroBoxed.Wrapper>
    <HeroBoxed.LeftColumn>
      <Content />
    </HeroBoxed.LeftColumn>
    <HeroBoxed.RightColumn>
      <Photo />
    </HeroBoxed.RightColumn>
    <MemphisPattern sx={styles.pattern} />
  </HeroBoxed.Wrapper>
)
